import fetch from '../utils/fetch'
import baseURL2 from '../utils/config'


//白名单列表列表
export function GetUserWhiteListPage(params) {
    return fetch({
        url:'api/Adm_User/GetUserWhiteListPage',
        method: 'get',
        params
    })
}



//添加白名单
export function AddUserWhiteList(a,b,c) {
    return fetch({
        url:'api/Adm_User/AddUserWhiteList?UserName='+a+'&Phone='+b+'&LimitNumber='+c,
        method: 'post',
        
    })
}


//修改白名单信息   编辑/启用/禁用/删除
export function UpWhiteList(params) {
    return fetch({
        url:'api/Adm_User/UpWhiteList',
        method: 'post',
        data:params
    })
}