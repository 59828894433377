import axios from 'axios'
import baseURL from './config'
import { getToken, removeToken } from '@/utils/auth'
import { MessageBox, Message } from 'element-ui'
import router from '../router'
// import { Loading } from 'element-ui';

// 创建axios实例
const service1 = axios.create({
    baseURL: 'https://api1.tuansudi.com/',  //url = base api url + request url
    withCredentials: true    // send cookies when cross-domain requests
})

// let loadingInstance = Loading.service({
//     lock: true,
//     text: 'Loading',
//     spinner: 'el-icon-loading',
//     background: 'rgba(0, 0, 0, 0.7)',
//     text: '正在请求数据，请稍等...'
// });

// request拦截器
service1.interceptors.request.use(
    config => {
        console.log(config.url ,'config.url ')
        if (config.url === 'https://restapi.amap.com/v3/config/district?key=e3d9d41136d0582c5984ef030aad37eb') {
        return config;
    }else{
        if (getToken()) {
            config.headers['Authorization'] = `Bearer ${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers['Content-Type'] = 'application/json'
        return config;
    }
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response 拦截器
service1.interceptors.response.use(
    response => {
        const code = response.status
        if (code < 200 || code > 300) {
            Message.error({
                message: response.message
            })
            return Promise.reject('error')
        } else {
            // loadingInstance.close();
            return response.data
        }
    },
    error => {
        let code = 0
        try {
            code = error.response.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                Message.error({
                    message: '网络请求超时',
                    duration: 5000
                })
                return Promise.reject(error)
            }
        }
        if (code) {
            console.log(code);
            // if (code == 500) {
            //     MessageBox.confirm(
            //         '接口请求失败,您可以继续留在该页面，或重新登录',
            //         '系统提示',
            //         {
            //             confirmButtonText: '重新登录',
            //             cancelButtonText: '取消',
            //             type: 'warning'
            //         }
            //     ).then(() => {

            //         window.location.href = "https://new.likewon.cn/#/"
            //         removeToken();
            //         stateCookies.setState("false");
            //     })

            // }
            if (code === 401) {
                MessageBox.confirm(
                    '登录已过期，您可以继续留在该页面，或重新登录',
                    '系统提示',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).then(() => {
                    //store.dispatch('ResetToken')
                    router.replace({
                        path: '/',
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    })
                    //window.location.href = "https://new.likewon.cn/#/"
                    removeToken();
                    stateCookies.setState("false");
                    // router.push({ path: '/' })
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                })
            } else if (code === 403) {
                router.push({ path: '/401' })
            } else {
                const errorMsg = error.response.data.msg
                console.log(errorMsg);

                if (errorMsg !== undefined) {
                    Message.error({
                        message: errorMsg,
                        duration: 5000
                    })
                }
            }
        } else {

            // window.open('https://new.likewon.cn/#/', '_self' ) //跳转到外部域OA首页
            Message.error({
                message: '接口请求失败',
                duration: 5000
            })
        }
        return Promise.reject(error)
    }
)
export default service1
